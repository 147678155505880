import React, { useState, useEffect, useRef, forwardRef, useForm } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, OverlayTrigger, Tooltip, Col, Row, Tabs, Tab, Table, Select, ProgressBar, Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FaQrcode } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import DatePicker from 'react-datepicker';
import { Show } from '../../../hooks/Show';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Sidebar from '../../../components/Navigation/Sidebar';
import Topbar from '../../../components/Navigation/Topbar';
import Grid from '../../../components/Grid';
import Timeline from '../../../components/Modal/WMS/Orders/Timeline';
import { CompanyTypesEnum } from '../../../utils/enumsUtil';
import { formatDateWithCustomFormat } from "../../../utils/formatDate";
import { isNullOrUndefined } from '../../../utils/functionsUtils';
import  Downloader from '../../../components/Common/DownloaderSpinner';

import io from "socket.io-client";
import { LabelDownloadHistory } from './LabelDownloadedHistoric';

const SOCKET_SERVER_URL = process.env.REACT_APP_REPORT_URL+'/labelDownloader';

const WmsOrders = ({
  reduxGetOrders,
  wmsOrders,
  reduxGetWMSOrderDetails,
  wmsOrderDetails,
  reduxEnlistmentWMSOrder,
  orderEnlistment,
  reduxPreparedWMSOrder,
  orderPrepared,
  reduxLabelTrackingRequest,
  pdfBase64,
  successfulGetLabel,
  reduxResetLabelsPDF,
  reduxGetLogisticsOperators,
  logisticsOperators,
  reduxGetClients,
  shippers,
  reduxGetBranches,
  branches,
  reduxGetCompanies,
  companies,
}) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const countryUser = userData.idCountry;
  const countryUserStr = countryUser ? countryUser.toString() : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [activeTab, setActiveTab] = useState('orders');
  const [idOrderSelected, setIdOrderSelected] = useState(null);
  const [selectedOption, setSelectedOption] = useState('all');
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [limitValue, setLimitValue] = useState(0);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [preparationOrders, setPreparationOrders] = useState([]);
  const [dispatchOrders, setDispatchOrders] = useState([]);
  const [hubOperatorsOptions, setHubOperatorsOptions] = useState(null);
  const [socket, setSocket] = useState(null);
  const [percentaje, setPercentaje] = useState(0)
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [hubOptions, setHubOptions] = useState(null);
  const [showLoadSpinner, setShowLoadSpinner] = useState(false);

  const preparationInputRef = useRef(null);
  const dispatchInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const urlWithTokens = `${process.env.REACT_APP_FRONTEND_URL}/wms/scanner/?token=${token}`;
  const [showModal, setShowModal] = useState(false);
  const CustomDateRangePicker = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete="Off"
    />
  ));
  
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleOpenTimeline = (numOrder) => {
    setIdOrderSelected(numOrder);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    setIdOrderSelected(null);
    setShowTimeline(false);
  };

  const handledChangeDatePicker = (dates) => {
    const [start, end] = dates;

    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {

    const token = window.localStorage.getItem("token");

    const socketClient = io.connect(SOCKET_SERVER_URL, { query: `token=${token}`, transports: ['polling', 'websocket']});

    socketClient.on('connect', () => {
      console.log('Connected to socket server');
      if(!showProgressBar){
        setShowProgressBar(true)
        socketClient.emit('joinRoom', {room: localStorage.getItem('token')})
      }
    });

    socketClient.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });

    socketClient.on('message', (message) => {
      console.log(message);
    });

    socketClient.on('labelDownloadProgress', (progress) => {
      setPercentaje(progress);
    })

    setSocket(socketClient)

    return () => {
      socketClient.disconnect();
      if (socket) {
        socket.disconnect();
      }
    };

  },[]);

  useEffect(() => {
    confirmIfDownloadBarProgressMustBeHide()
  }, [percentaje])

  const confirmIfDownloadBarProgressMustBeHide = () => {
    if (percentaje === 100) {
      setTimeout(() => {
        setShowProgressBar(false)
        setPercentaje(0)
        setShowLoadSpinner(false)
      }, 4000)
    }
  }

  const joinRoom = (room) => {
    if (socket) {
      socket.emit('joinRoom', { room });
    }
  };

  useEffect(() => {
    setOffset(1);
    setSearch('');
    setCurrentPage(1);
    reduxGetOrders({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, []);

  useEffect(() => {
    reduxGetCompanies({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
  }, [reduxGetCompanies]);

  useEffect(() => {
    reduxGetOrders({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage, offset, search, reduxGetOrders])

  useEffect(() => {
    if (activeTab === 'preparation' && preparationInputRef.current) {
      preparationInputRef.current.focus();
    }
    if (activeTab === 'dispatch' && dispatchInputRef.current) {
      dispatchInputRef.current.focus();
    }

    if (activeTab === 'orders') {
      setOffset(10);
      setSearch('');
      setCurrentPage(1);
      reduxGetOrders({
        page: currentPage,
        offset: offset,
        search: search,
      });
    }
    
    if (activeTab === 'preparation') {
      setOffset(5000);
      setCurrentPage(1);
      reduxGetOrders({
        page: currentPage,
        offset: offset,
        search: 'current_status|alistamiento',
      });
    }

    if (activeTab === 'dispatch') {   
      setOffset(5000);
      setCurrentPage(1);
      reduxGetOrders({
        page: currentPage,
        offset: offset,
        search: 'current_status|preparada',
      });
    }

  }, [activeTab]);

  useEffect(() => {
    if(isNullOrUndefined(pdfBase64) && successfulGetLabel === true){
      Swal.fire("Resultado de descarga de etiquetas", "No se tienen órdenes pendientes de alistamiento", 'info');
      setShowProgressBar(false)
      setShowLoadSpinner(false)
    }
    reduxResetLabelsPDF();
    if(!isNullOrUndefined(pdfBase64) && successfulGetLabel === true){
      reduxGetOrders({
        page: currentPage,
        offset: offset,
        search: search,
      });
    }
  }, [pdfBase64, successfulGetLabel])

  const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

  const handleShowDetail = (row) => {
    setShowDetail(true);
    setSelectedOrder(row);
    if (row.num_order) {
      reduxGetWMSOrderDetails(row.num_order);
    }
  };

  useEffect(() => {
    if (wmsOrderDetails) {
      setSelectedOrderDetails(wmsOrderDetails);
    }
  }, [wmsOrderDetails]);

  const handleCloseDetail = () => {
    setShowDetail(false);
  };

  const openModalDownload = () => {
    setShowModalDownload(true);    
    reduxGetLogisticsOperators();
    reduxGetClients();
    joinRoom(localStorage.getItem('token'))
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });

  };

  const closeModalDownload = () => {
    setShowModalDownload(false);
    setSelectedOption('all');
    setSelectedOperator(null);
    setFromDate(null);
    setToDate(null);
    setSelectedBranch(null);
    setLimitValue(0);
    reduxGetOrders({
      page: currentPage,
      offset: offset,
      search: search,
    });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value); 
  };

  const handleOperatorChange = (event) => {
    setSelectedOperator(event.target.value); 
  };


  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handlePreparationSearch = (e) => {
        e.preventDefault();
      let trackingId = preparationInputRef.current.value;

      const orderInWms = wmsOrders.items.find(order => {
          if (!isNullOrUndefined(trackingId) && order.id_operator === 76 && trackingId.includes('.')) {
            trackingId = trackingId.replace(/^\d+\.(\d+)\.\d{3}$/, '$1');
          }
          return order.tracking_id === trackingId && order.current_status === "En alistamiento";
      });

      const orderInPrev = preparationOrders ? preparationOrders.find(order => order.tracking_id == trackingId) : null;

      if (orderInWms) {
        if (isNullOrUndefined(orderInPrev)) {
          reduxEnlistmentWMSOrder(orderInWms);
          setPreparationOrders(prevOrders => [...prevOrders, { ...orderInWms, current_status: "Preparada" }]);
        } else {
          Swal.fire('Error', 'La orden ya está en la lista de preparación.', 'error');
        }
      } else {
        Swal.fire('Error', 'El estado de la orden no corresponde o la orden no se encuentra.', 'error');
      }
      preparationInputRef.current.value = '';
  };

  const handleDispatchSearch = (e) => {
      e.preventDefault();
      let trackingId = dispatchInputRef.current.value;

      const orderInWms = wmsOrders.items.find(order => {
        if (!isNullOrUndefined(trackingId) && order.id_operator === 76 && trackingId.includes('.')) {      
          trackingId = trackingId.replace(/^\d+\.(\d+)\.\d{3}$/, '$1');
        }
          return order.tracking_id === trackingId && order.current_status === "Preparada";
      });

      const orderInDispatch = dispatchOrders ? dispatchOrders.find(order => order.tracking_id == trackingId) : null;

      if (orderInWms) {
        if (isNullOrUndefined(orderInDispatch)) {
          reduxPreparedWMSOrder(orderInWms);
          setDispatchOrders(dispatchOrders => [...dispatchOrders, { ...orderInWms, current_status: "Despachado" }]);
        } else {
          Swal.fire('Error', 'La orden ya está en la lista de despacho.', 'error');
        }
      } else {
        Swal.fire('Error', 'El estado de la orden no corresponde o la orden no se encuentra.', 'error');
      }
      dispatchInputRef.current.value = '';
  };

  const handleDownload = () => {
    const payload = { option: selectedOption };
    payload.all = true;
    payload.limit = null;
    // if (selectedOption === 'limit') {
    //   payload.limit = limitValue;
    //   payload.all = null;
    // }
    // if (selectedOption === 'all') {
    //   payload.limit = null;
    //   payload.all = true;
    // }
    if (selectedOperator !== 'all') {
      payload.operator = selectedOperator;
    } else {
      payload.operator = null;
    }
    if (selectedBranch !== 'all') {
      payload.branch = selectedBranch;
    } else {
      payload.branch = null;
    }
    if (!isNullOrUndefined(fromDate) && !isNullOrUndefined(toDate)) {
      payload.from = formatDateWithCustomFormat(fromDate, { format: 'YYYY-MM-DD 23:59:59' });
      payload.to = formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' });
    } else {
      payload.from = null;
      payload.to = null;
    } 
    payload.shipper = null;
    reduxLabelTrackingRequest(payload);   

    closeModalDownload();
    setShowProgressBar(true)
    setShowLoadSpinner(true)
  };

  const handlePreparationBlur = () => {
    if (preparationInputRef.current) {
      preparationInputRef.current.focus();
    }
  };

  const handleDispatchBlur = () => {
    if (dispatchInputRef.current) {
      dispatchInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (!Array.isArray(companies.items) || companies.items.length === 0) return;
    
    const groupedOptions = [];
    const dspCompanies = companies.items.filter(e => 
      e.companyType === CompanyTypesEnum.DSP  && e.isActive === true &&
      (countryUserStr === null || e.country === countryUserStr)
    );
  
    if (dspCompanies.length > 0) {
      groupedOptions.push({
        label: "DSP",
        options: dspCompanies.map(e => ({ label: e.businessName, value: e.idCompany, country: e.country }))
      });
    }
  
    const logCompanies = companies.items.filter(e => 
      e.companyType === CompanyTypesEnum.LOGISTICS_OPERATOR && e.isActive === true &&
      (countryUserStr === null || e.country === countryUserStr)
    );
  
    if (logCompanies.length > 0) {
      groupedOptions.push({
        label: "Operador Tradicional",
        options: logCompanies.map(e => ({ label: e.businessName, value: e.idCompany, country: e.country }))
      });
    }
    
    setHubOperatorsOptions(groupedOptions);
  }, [companies, countryUser]);


  const columns = [
    {
      title: '# Orden',
      field: 'num_order',
      searchable: true,
      render: rowData => <span>{rowData.num_order}</span>
    },
    {
      title: '# Guía',
      field: 'tracking_id',
      searchable: true,
      render: rowData => <span>{rowData.tracking_id}</span>
    },
    {
      title: 'ID externo',
      field: 'external_id',
      searchable: true,
      render: rowData => <span>{rowData.external_id}</span>
    },
    {
      title: 'Operador',
      field: 'operator',
      searchable: true,
      render: rowData => <span>{rowData.operator}</span>
    },
    {
      title: 'Fecha de Creación',
      field: 'created_at',
      searchable: true,
      render: rowData => <span>{formatDateWithCustomFormat(rowData.created_at, { format: "DD-MM-YYYY HH:mm:ss" })}</span>
    },
    {
      title: 'Estado',
      field: 'current_status',
      searchable: true,
      render: rowData => <span>{rowData.current_status}</span>
    },
    {
      title: 'Opciones',
      render: rowData => (
        <>
          <Show when='feature:read-order'>
            <button title='Ver Detalle' className='btn btn-primary btn-sm btn-circle mr-2' type='button' onClick={() => handleShowDetail(rowData)}>
              <i className='fas fa-eye fa-xs'></i>
            </button>
          </Show>
          {rowData.idOperator == null && (
            <Show when='feature:edit-order'>
              <button title='Timeline' className='btn btn-success btn-sm btn-circle mr-2' type='button' onClick={() => handleOpenTimeline(rowData.num_order)}>
                <i className='fas fa-info fa-xs'></i>
              </button>
            </Show>
          )}
        </>
      )
    }
  ];

  const columnsWithoutOptions = columns.filter(column => column.title !== 'Opciones');

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Órdenes de Alistamiento</h1>
                {activeTab === 'orders' && !showLoadSpinner && (
                  <Show when='feature:download-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar Etiquetas')}>
                      <button
                        className='btn btn-warning btn-circle'
                        type='button'
                        onClick={openModalDownload}>
                        <i className='fas fa-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                )}
                { showLoadSpinner && (
                   <Spinner animation="grow" variant="warning" />
                ) }
                
                <div className="ml-3"> 
                <button
            className='btn btn-warning rounded-circle d-flex align-items-center justify-content-center'
            onClick={handleShow}
            style={{ width: '42px', height: '42px', cursor: 'pointer' }}
        >
            <FaQrcode size={24} />
        </button>

          <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Scanner órdenes WMS</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                <p>Escanea el QR para usar el scanner en tu teléfono</p>
                    <QRCode value={urlWithTokens} size={270} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
               
              </div>
              <p className='mb-4'>Módulo de Gestión de órdenes</p>
              <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                <Tab eventKey='orders' title='Listado de Órdenes'>
                  <form>
                    <div className='card shadow mb-4'>
                      <div className='card-body'>
                        { showProgressBar && (
                          <ProgressBar animated={percentaje !== 100} now={percentaje} size={'sm'} className='mb-2' title='Descarga de etiquetas en progreso' />
                        ) }
                        <Grid
                          cols={columns}
                          data={wmsOrders && Object.keys(wmsOrders).length > 0 ? wmsOrders.items : []}
                          page={wmsOrders && Object.keys(wmsOrders).length > 0 ? Number(wmsOrders.page) : 1}
                          pages={wmsOrders && Object.keys(wmsOrders).length > 0 ? Number(wmsOrders.totalPages) : 0}
                          total={wmsOrders && wmsOrders.hasOwnProperty('total') ? wmsOrders.total : 0}
                          offset={offset}
                          onChangePage={(page) => {
                            setCurrentPage(page);
                            reduxGetOrders({
                              page: page,
                              offset: offset,
                              search: search,
                            });
                          }}
                          onChangeRange={(value) => {
                            setOffset(value);
                            setCurrentPage(1);
                            reduxGetOrders({
                              page: 1,
                              offset: value,
                              search: search,
                            });
                          }}
                          defaultValue={search}
                          onChangeSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                            reduxGetOrders({
                              page: 1,
                              offset: offset,
                              search: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </Tab>
                <Tab eventKey='preparation' title='Preparación de Órdenes'>
                  <div className='card shadow mb-4'>
                    <div className='card-body'>
                      <div className='input-group inline'>
                        <form onSubmit={handlePreparationSearch}>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Escanee la Orden'
                            ref={preparationInputRef}
                            onBlur={handlePreparationBlur}
                          />
                        </form>
                      </div>
                      <div>
                        <Grid cols={columnsWithoutOptions} data={preparationOrders} />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='dispatch' title='Despacho de Órdenes'>
                  <div className='card shadow mb-4'>
                    <div className='card-body'>
                      <div className='input-group inline'>
                        <form onSubmit={handleDispatchSearch}>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Escanee la Orden'
                            ref={dispatchInputRef}
                            onBlur={handleDispatchBlur}
                          />
                        </form>
                      </div>
                      <div>
                        <Grid cols={columnsWithoutOptions} data={dispatchOrders} />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey={'labelsHistoric'} title={'Histórico de Etiquetas'}>
                  { activeTab === 'labelsHistoric' && (
                      <LabelDownloadHistory />
                  ) }
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div>
          <Modal
            size='xl'
            show={showTimeline}
            onHide={handleCloseTimeline}
            onEscapeKeyDown={handleCloseTimeline}
            backdrop='static'
            keyboard={true}>
            <Modal.Header closeButton>
              <Modal.Title>Timeline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Timeline numOrder={idOrderSelected}></Timeline>
            </Modal.Body>
          </Modal>
        </div>
        <div>
          <Modal
            size='lg'
            show={showModalDownload}
            onHide={closeModalDownload}
            onEscapeKeyDown={closeModalDownload}
            backdrop='static'
            keyboard={true}>
            <Modal.Header closeButton>
              <Modal.Title>Descargar Etiquetas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group as={Col}>
                  <Row>
                    {/* <Col>
                      <Form.Label>Opciones</Form.Label>
                      <Form.Control as="select" value={selectedOption} onChange={handleOptionChange}>
                        <option value="all">Todos</option>
                        <option value="limit">Limitar a:</option>
                      </Form.Control>
                    </Col>
                    <Col>
                      {selectedOption === 'limit' && (
                        <Form.Group>
                          <Form.Label>Ingrese el límite</Form.Label>
                          <Form.Control
                            type="text"
                            value={limitValue}
                            onChange={handleLimitChange}
                          />
                        </Form.Group>
                      )}
                    </Col> */}
                    <Col>
                    <div className='form-group'>
                      <label htmlFor="from" className="form-label">Desde - Hasta</label>
                      <DatePicker
                        selected={fromDate}
                        onChange={handledChangeDatePicker}
                        startDate={fromDate}
                        endDate={toDate}
                        maxDate={new Date()}
                        isClearable={true}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomDateRangePicker />}
                        selectsRange
                      />
                    </div>
                    </Col> 
                    <Col>
                    <Form.Group>
                      <Form.Label>Operador</Form.Label>
                      <Form.Control as="select" value={selectedOperator} onChange={handleOperatorChange}>
                        <option value="all">Todos</option>
                        {hubOperatorsOptions && hubOperatorsOptions.length > 0 && hubOperatorsOptions
                        .map((group, groupIndex) => (
                          <optgroup key={groupIndex} label={group.label}>
                            {group.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                      <Form.Label>HUB</Form.Label>
                      <Form.Control as='select' value={selectedBranch} onChange={handleBranchChange} >
                      <option value="all">Todos</option>
                      {branches.items && branches.items.length > 0 && branches.items
                      .filter(e => e.isActive === true && (countryUserStr === null || e.idCountry == countryUserStr))
                      .map((ele) => (
                        <option key={ele.idBranch} value={ele.idBranch}>
                          {ele.branchName}
                        </option>
                      ))}
                      </Form.Control>
                    </Form.Group>
                    </Col> 
                                      
                  </Row>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModalDownload}>
                Cerrar
              </Button>
              <Button variant="primary" onClick={handleDownload}>
                Descargar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            size='xl'
            show={showDetail}
            onHide={handleCloseDetail}
            onEscapeKeyDown={handleCloseDetail}
            backdrop='static'
            keyboard={true}>
            <Modal.Header closeButton>
              <Modal.Title>Detalles de la Orden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th># Orden</th>
                    <th># Guía</th>
                    <th>ID Externo</th>
                    <th>Fecha de Creación</th>
                  </tr>                  
                </thead>
                <tbody>
                  {selectedOrder && (
                    <tr>
                      <td>{selectedOrder.num_order}</td>
                      <td>{selectedOrder.tracking_id}</td>
                      <td>{selectedOrder.external_id}</td>
                      <td>{formatDateWithCustomFormat(selectedOrder.created_at, { format: "DD-MM-YYYY HH:mm:ss" })}</td>
                    </tr>
                  )}                  
                </tbody>
              </Table>
              <Table striped bordered hover>                
                <thead>
                  <tr>
                    <th colSpan="4" className="text-center">Listado de Productos</th>
                  </tr>
                  <tr>
                    <th># Producto</th>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrderDetails && selectedOrderDetails.length > 0 ? (
                      selectedOrderDetails.map((detail, index) => (
                        <tr key={index}>
                          <td>{detail.idProduct}</td>
                          <td>{detail.product.description}</td>
                          <td>{detail.quantity}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">No hay detalles disponibles</td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDetail}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wmsOrders: state.warehouseState.wmsOrders,
    wmsOrderDetails: state.warehouseState.wmsOrderDetails,
    orderEnlistment: state.warehouseState.orderEnlistment,
    orderPrepared: state.warehouseState.orderPrepared,
    successfulGetLabel: state.warehouseState.successfulGetLabel,
    pdfBase64: state.warehouseState.pdfBase64,
    logisticsOperators: state.companyState.logisticsOperators,
    shippers: state.companyState.clients,
    branches: state.branchState.branches,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOrders: (payload) =>
      dispatch({
        type: 'GET_WMS_ORDERS_REQUEST',
        value: payload,
      }),
    reduxGetWMSOrderDetails: (payload) =>
      dispatch({
        type: 'READ_WMSORDER_DETAIL_REQUEST',
        value: payload,
      }),
    reduxEnlistmentWMSOrder: (payload) =>
      dispatch({
        type: 'ENLISTMENT_WMSORDER_REQUEST',
        value: payload,
      }),
    reduxPreparedWMSOrder: (payload) =>
      dispatch({
        type: 'PREPARED_WMSORDER_REQUEST',
        value: payload,
      }),
    reduxLabelTrackingRequest: (payload) =>
      dispatch({
        type: 'LABEL_TRACKING_REQUEST',
        value: payload,
      }),
    reduxResetLabelsPDF: () => 
      dispatch({ 
        type: 'RESET_LABEL_TRACKING_FORM'
      }),
    reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetClients: (payload) =>
      dispatch({
          type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
          value: payload,
      }),
    reduxGetBranches: (payload) =>
      dispatch({
          type: 'FETCH_BRANCHES_REQUEST',
          value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIES_REQUEST',
        value: payload,
      }),
      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WmsOrders);