import Select from 'react-select'
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import { isNullOrUndefined } from '../../utils/functionsUtils';

const ReschedulingByIds = ({
    successfulUpdateMasivRescheduling,
    reduxPatchReschedulingMasiv,
    masivGuidesUpdated,
}) => {

    const { 
        control,
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
        reset,
     } = useForm({
        defaultValues: {
            byTrackings: '1'
        }
    });

    const [ disableSubmit, setDisableSubmit ] = useState();

    useEffect(()=> {
        setDisableSubmit(true)
    }, []);

    useEffect(() => {
        if (successfulUpdateMasivRescheduling) {
            Swal.fire(
                (masivGuidesUpdated.errorState ? "Proceso incompleto!": "Proceso exitoso!"),
                `${masivGuidesUpdated.updated} de ${masivGuidesUpdated.total} Guías/Id Externo reprogramados exitosamente. ` +
                (masivGuidesUpdated.errorState
                ? `Alguna(s) guía(s) ingresada(s) no se actualizaron. `
                : ``) ,
                (masivGuidesUpdated.errorState ? "warning" : "success")
            );
        }
    }, [successfulUpdateMasivRescheduling]);

    const onSubmit = (data) => {
        const errors = validGuides();
        if(errors.length > 0){
            Swal.fire('Archivo errado', 'Señor Usuario, hay envios duplicadas. Por favor revise los datos ingresadas.', 'error');
            return;
        }
        Swal.fire({
            title: "¿Esta seguro de actualizar las guías solicitadas?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'SI',
            cancelButtonText: 'NO'
        }).then((result) => {
            if (result.isConfirmed) {
                reduxPatchReschedulingMasiv(data);
                reset({
                    byTrackings: '1',
                    shipmentIds: null,
                    rescheduleResolution: null,
                    comment: null,
                });
                setDisableSubmit(true);
            }
        });
    };

    const validGuides = () => {
        const shipmentIds = getValues('shipmentIds');
        const guides = shipmentIds.split('\n');
        const validGuide = [];
        const errors = []

        for (let i = 0; i < guides.length; i++) {
            let guide = guides[i];
            if( isNullOrUndefined(guide) ) continue;
            guide = guide.trim();
            if( validGuide.indexOf(guide) > -1 ){
                errors.push({ key: guide, message: `Guia Duplicada` });
                continue;
            }
            validGuide.push(guide);
        }
        return errors;
    }

    const validateData = (event) => {
        const shipmentIds = getValues('shipmentIds');
        const rescheduleResolution = getValues('rescheduleResolution');
        const comment = getValues('comment');
        if(!isNullOrUndefined(shipmentIds) && !isNullOrUndefined(rescheduleResolution) && !isNullOrUndefined(comment)){
            setDisableSubmit(false);
        }
    }

    return (
        <div className="card shadow mb-4">
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <table style={{ width: "100%" }}>
                        <tr className="row">
                            <td style={{ width: "30%" }}>
                                <div className="form-group col-md-12">
                                    <div className="form-check form-check-inline">
                                        <input id="byGuia" className="form-check-input" type="radio" value="1" {...register('byTrackings')}/> 
                                        <label className="form-check-label" htmlFor="byGuia">Guías</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="byAddress" className="form-check-input" type="radio" value="2" {...register('byTrackings')}/> 
                                        <label className="form-check-label" htmlFor="byAddress">ID Externo</label>
                                    </div>
                                    <textarea
                                        id="shipmentIds"
                                        className={`form-control form-control-user ${
                                            errors.shipmentIds && "is-invalid"
                                        }`}
                                        name='shipmentIds'
                                        {...register("shipmentIds", {
                                            required: true,
                                            pattern: {
                                                value: /^[a-zA-Z0-9\s]*$/i,
                                                message: "invalid guide",
                                            },
                                        })}
                                        onChange={validateData}
                                        rows={12}
                                        cols={5}
                                    />
                                    {errors.shipmentIds && (
                                        <span className="invalid-feedback">
                                            Las guías no son válidas
                                        </span>
                                    )}
                                </div>
                            </td>
                            <td className="d-flex flex-column" style={{ width: "50%" }}>
                                <div className="form-group">
                                    <label htmlFor="state" className="form-label">
                                        Solución
                                    </label>
                                    <Controller
                                        control={control}
                                        defaultValue={null}
                                        name='rescheduleResolution'
                                        rules={{ required: 'El campo solución es obligatorio' }}
                                        render={({ field }) => (
                                        <> 
                                            <Select
                                                {...field}
                                                name='rescheduleResolution'
                                                onChange={(value) => {
                                                    setValue('rescheduleResolution', value || '');
                                                    validateData(value);
                                                }}
                                                isClearable
                                                options={[
                                                    { value: 2, label: 'Reprogramar' },
                                                    { value: 3, label: 'Generar Devolución' },
                                                    { value: 4, label: 'Ya entregado' },
                                                ]}
                                            />
                                            {errors.rescheduleResolution &&
                                                <span className='error-message'>{errors.rescheduleResolution.message}</span>
                                            }
                                        </>
                                        )} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="comment" className="form-label">
                                        Observaciones
                                    </label>
                                    <Controller
                                        control={control}
                                        name='comment'
                                        rules={{ required: 'El campo observaciones es obligatorio' }}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    id="comment"
                                                    onChange={(value) => {
                                                        setValue('comment', value.target.value || '');
                                                        validateData(value);
                                                    }}
                                                    type="text"
                                                    className={`form-control form-control-user`}
                                                    style={{ marginTop: "5px", marginBottom: "10px" }}
                                                />
                                                {errors.comment &&
                                                    <span className='error-message'>{errors.comment.message}</span>
                                                }
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="d-flex form-group justify-content-center">
                                    <Button
                                        disabled={disableSubmit}
                                        variant="primary"
                                        type="submit"
                                        id="btnSearch"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </form>
                
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
  return {
    successfulUpdateMasivRescheduling: state.reschedulingReducer.successfulUpdateMasivRescheduling,
    masivGuidesUpdated: state.reschedulingReducer.masivGuidesUpdated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxPatchReschedulingMasiv: (payload) =>
      dispatch({
        type: 'UPDATE_MASIV_RESCHEDULING_REQUEST',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReschedulingByIds);